<template>
  <div>
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->

    <div class="container">
      <!-- 纠纷处理 -->
      <div class="ag-con">
       
        <div class="article-content">
          <h2 align="center">
            <strong>用户权益保障措施</strong>
          </h2>
          <strong>1.账号注册</strong><br />
          1.1
          乙方承诺以其真实身份注册成为甲方的用户，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。<br />
          1.2
          乙方以其真实身份注册成为甲方用户后，需要修改所提供的个人身份资料信息的，甲方应当及时、有效地为其提供该项服务。<br />
          <strong>2.用户账号使用与保管</strong><br />
          2.1
          根据必备条款的约定，甲方有权审查乙方注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；乙方有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给乙方和他人的民事权利造成损害的，应当承担由此产生的法律责任。<br />
          2.2乙方对登录后所持账号产生的行为依法享有权利和承担责任。<br />
          2.3
          乙方发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据甲方公布的处理方式通知甲方，并有权通知甲方采取措施暂停该账号的登录和使用。<br />
          2.4
          甲方根据乙方的通知采取措施暂停乙方账号的登录和使用的，甲方应当要求乙方提供并核实与其注册身份信息相一致的个人有效身份信息。<br />
          2.4.1
          甲方核实乙方所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停乙方账号的登录和使用。<br />
          2.4.2
          甲方违反2.4.1款项的约定，未及时采取措施暂停乙方账号的登录和使用，因此而给乙方造成损失的，应当承担其相应的法律责任。<br />
          2.4.3
          乙方没有提供其个人有效身份证件或者乙方提供的个人有效身份证件与所注册的身份信息不一致的，甲方有权拒绝乙方上述请求。<br />
          2.5
          乙方为了维护其合法权益，向甲方提供与所注册的身份信息相一致的个人有效身份信息时，甲方应当为乙方提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。<br />
          <strong>3.服务的中止与终止</strong><br />
          3.1乙方有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，甲方应当立即终止对乙方提供服务。<br />
          3.2乙方在接受甲方服务时实施不正当行为的，甲方有权终止对乙方提供服务。该不正当行为的具体情形应当在本协议中有明确约定或属于甲方事先明确告知的应被终止服务的禁止性行为，否则，甲方不得终止对乙方提供服务。<br />
          3.3乙方提供虚假注册身份信息，或实施违反本协议的行为，甲方有权中止对乙方提供全部或部分服务；甲方采取中止措施应当通知乙方并告知中止期间，中止期间应该是合理的，中止期间届满甲方应当及时恢复对乙方的服务。<br />
          3.4
          甲方根据本条约定中止或终止对乙方提供部分或全部服务的，甲方应负举证责任。<br />
          <strong>4.用户信息保护</strong><br />
          4.1
          甲方要求乙方提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向乙方公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护乙方的个人信息资料的安全。<br />
          4.2未经乙方许可甲方不得向任何第三方提供、公开或共享乙方注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：<br />
          4.2.1 乙方或乙方监护人授权甲方披露的；<br />
          4.2.2 有关法律要求甲方披露的；<br />
          4.2.3 司法机关或行政机关基于法定程序要求甲方提供的；<br />
          4.2.4 甲方为了维护自己合法权益而向乙方提起诉讼或者仲裁时；<br />
          4.2.5 应乙方监护人的合法要求而提供乙方个人身份信息时。<br />
    
          &nbsp;<br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    // NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  methods() {
    document.title = "灵素斋";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  .ag-con {
    margin: 30px auto;
    // 文字容器
    .t-con {
      margin: 30px auto;
      font-size: 14px;
      line-height: 40px;
      text-align: left;
      p {
        margin: 10px auto;
      }
    }
  }
}
</style>
