<template>
  <div class="n-footer">
    <div class="container">
      <!-- 左侧信息 -->
      <div class="f-l">
        <p>公司名称：灵素斋（北京）科技发展有限公司</p>
        <p>公司域名：lingsuzhai.com</p>
        <!-- <p>公司邮箱：2124440690@qq.com</p> -->
        
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >网站备案：京ICP备2023005873号-1</a
          >
        </p>
        <p>联系地址：北京市海淀区知春路7号致真大厦D座4层D4235</p>
        
      </div>
      <!-- 右侧信息 -->
      <div class="f-r">
        <div class="f-top">
          <a href="">网站首页</a> <span>|</span> <a href="">收费资讯</a>
          <span>|</span> <a href="/#/ac2">纠纷处理</a> <span>|</span>
          <a href="/#/xy">用户服务协议</a> <span>|</span>
          <a href="/#/ac3">用户权益保障措施</a>
        </div>
        <h2>北京浩元互娱科技有限公司</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-footer {
  width: 100%;
  height: 80px;
  //   background-color: #c60023;
  .container {
    height: 100%;
    @include flex();
    // 左侧
    .f-l {
      p {
        color: #888;
        margin: 5px 0;
        a {
          color: #888;
        }
      }
    }
    // 右侧
    .f-r {
      .f-top {
        margin-bottom: 15px;
        a {
          font-size: 12px;
          color: #888;
        }
        span {
          font-size: 14px;
          margin: 0 3px;
          color: #888;
        }
      }

      h2 {
        text-align: center;
      }
    }
  }
}
</style>