<template>
  <div class="index">
    <div class="container">
      <!-- 资讯区块1 -->
      <div class="a1">
        <!-- 左侧 -->
        <div class="l">
          <img src="/imgs/b1.jpg" alt="" />
        </div>
        <!-- 右侧 -->
        <div class="r">
          <!-- 标题 -->
          <div class="r-title">
            <span>推荐</span>
            <h2  v-for="(item, index) in this.ac4"
            :key="index"
            @click="gopay()">{{item.title}}</h2>
          </div>

          <!-- 循环项 -->
          <div
            class="r-item"
            v-for="(item, index) in this.ac1"
            :key="index"
            @click="gopay()"
          >
            <!-- 小圆点 -->
            <i></i>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>

      <!-- 资讯区块2 -->
      <div class="a2">
        <!-- 标题 -->
        <div class="a2-title">热门板块</div>

        <!-- 容器 -->
        <div class="a2-con">
          <!-- 循环项 -->
          <div
            class="a2-item"
            v-for="(item, index) in this.ac2"
            :key="index"
            @click="gopay()"
          >
            <img :src="item.img" alt="" />
            <h2>{{ item.title }}</h2>
            <p>{{ item.jj }}</p>
            <h4>收费阅读：2元/篇</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="msub()"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="" src="/imgs/pay2.png" alt="" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  components: {
    Modal,
    // info,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      ac4:[],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "灵素斋";
    
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        console.log(data)
        
        // 咨询区块1
        this.myData = data;
        this.ac4 = data.slice(0,1);
        this.ac1 = data.slice(1, 9);
     
      });

      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
     
        this.ac2 = data.slice(0, 9);
     
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // this.$router.push({ path: "/login", query: { k: 1 } });
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇3元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  width: 100%;
  // height: 800px;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 30px;
  box-sizing: border-box;
  .container {
    height: 100%;
    // 资讯区块1
    .a1 {
      width: 100%;
      height: 350px;
      //   background-color: pink;
      @include flex();
      margin-bottom: 30px;
      //   左侧
      .l {
        width: 45%;
        height: 100%;
        // background-color: #c60023;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 右侧
      .r {
        width: 50%;
        height: 100%;
        // background-color: #ff6600;
        // margin-left: 30px;

        // 标题
        .r-title {
          width: 100%;
          height: 50px;
          background-color: #000;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            width: 70px;
            height: 100%;
            background-color: #c60023;
            display: inline-block;
            font-size: 18px;
            color: #fff;
            text-align: center;
            line-height: 50px;
          }

          h2 {
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            margin-left: 30px;
          }
        }

        // 循环项
        .r-item {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          padding-left: 5px;
          box-sizing: border-box;
          margin: 10px;
          cursor: pointer;
          i {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            // color: #333;
            display: inline-block;
            background-color: #333;
            margin-right: 10px;
          }
          span {
            color: #333;
            font-size: 14px;
          }
        }
      }
    }

    // 资讯区块2
    .a2 {
      // 标题
      .a2-title {
        width: 100%;
        height: 20px;
        border-left: 5px solid $colorZ;
        padding-left: 20px;
        box-sizing: border-box;
        margin: 30px 0;
        font-size: 14px;
        font-weight: bold;
      }

      //   容器
      .a2-con {
        width: 100%;
        // height: 700px;
        // background: pink;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        // 循环项
        .a2-item {
          width: 340px;
          height: 384px;
          background: #fff;
          padding: 0 15px;
          box-sizing: border-box;
          margin: 30px 30px;
          margin-left: 0;
          cursor: pointer;
          //   图片
          img {
            width: 100%;
            height: 230px;
          }

          h2 {
            font-size: 18px;
            font-weight: 400;
            margin-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 260px;
          }

          p {
            width: 100%;
            background-color: #fff;
            font-size: 14px;
            color: #888;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin: 20px 0;
          }

          h4 {
            text-align: right;
            font-size: 12px;
            color: #c60023;
          }
        }
      }
    }
  }
}
</style>